<template>
  <transition name="fade">
    <div v-show="showDialog" class="onboarding text-center pt-3">
      <h1 class="onboarding__title mt-3 mb-6">
        {{ translate('title') }}
      </h1>

      <div class="onboarding__guide">
        <div class="onbloarding__guide-box mr-2">
          <div style="display: contents">
            <img
              class="onboarding__guide-image"
              src="~/assets/images/onboarding/shopping_guide_1.png"
              alt=""
            />
          </div>
          <div class="onboarding__guide-text mt-2">
            1.{{ translate('step1') }}
          </div>
        </div>
        <div class="onbloarding__guide-box ml-2">
          <div style="display: contents">
            <img
              class="onboarding__guide-image"
              src="~/assets/images/onboarding/shopping_guide_2.png"
              alt=""
            />
          </div>
          <div class="onboarding__guide-text mt-2">
            2.{{ translate('step2') }}
          </div>
        </div>
      </div>

      <div class="onboarding__guide mt-6 mb-6">
        <div>
          <img
            class="onboarding__guide-image"
            src="~/assets/images/onboarding/shopping_guide_3.png"
            alt=""
          />
          <div class="onboarding__guide-text mt-2">
            3.{{ translate('step3') }}
          </div>
        </div>
      </div>

      <v-btn
        color="primary"
        width="280"
        class="onboarding__start-button"
        @click="onClickGoShoppingBtn"
      >
        {{ buttonText }}
      </v-btn>
      <p class="onboarding__notice-message mt-4">
        {{ translate('recommendations') }}
      </p>
    </div>
  </transition>
</template>

<script lang="ts">
const getQueryString = (value: any): string => {
  if (Array.isArray(value)) {
    return value[0] || ''
  }
  return value || ''
}

export default defineComponent({
  computed: {
    showDialog(): boolean {
      return this.$store.getters['onboarding/showDialog']
    },
    buttonText() {
      const chatStore = useChatStore()
      if (this.$store.getters.isWebCatalog || chatStore.isFirstVist()) {
        return this.translate('start')
      } else {
        return this.translate('backToShopping')
      }
    },
  },

  methods: {
    onClickGoShoppingBtn() {
      // 初回訪問時はchatStoreの初期化をここで行う
      if (this.$store.getters.isChat) {
        const route = useRoute()
        const router = useRouter()
        const chatStore = useChatStore()
        if (chatStore.isFirstVist()) {
          const languageStore = useLanguageStore()
          chatStore.initialize(languageStore.languageCode)

          setTimeout(async () => {
            const brandId = getQueryString(route.query?.brandId)
            if (brandId) {
              const { $gtmClickPush } = useNuxtApp()
              // @ts-ignore
              $gtmClickPush('chat_qrcode_scan_success', {
                scanValue: brandId,
              })
              chatStore.addBrandMessages(
                parseInt(brandId),
                languageStore.languageCode
              )
              await router.replace({
                query: {
                  ...route.query,
                  brandId: undefined,
                },
              })
            }
          }, 500)
        }
      }
      this.$store.commit('onboarding/closeDialog')
    },
    translate(key: string): string {
      const keyBase = 'onboarding'
      return this.$t(`${keyBase}.${key}`)
    },
  },
})
</script>

<style lang="sass" scoped>
button
  outline: none

.onboarding
  position: fixed
  z-index: 1111111
  width: 100vw
  height: 100dvh
  background: $main-bg-color
  white-space: pre-wrap
  padding: 30px
  overflow: auto

.onboarding__title
  font-size: 28px

.onboarding__guide
  display: flex
  justify-content: center
  align-items: flex-start

.onbloarding__guide-box
  flex: 1
  justify-content: center
  align-items: center

.onboarding__guide-image
    width: calc(16vh)
    max-width: 137px
    height: auto

.onboarding__guide-text
  font-size: 16px
  font-weight: bold

.onboarding__start-button
  display: block
  margin: 0 auto
  font-size: 18px
  border-radius: 10px
  font-weight: 600
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2)
  text-transform: none
  height: 10dvh
  max-height: 64px
  min-width: 240px

.onboarding__notice-message
  font-size: 12px

.fade-enter-active,
.fade-leave-active
  transition: opacity 0.4s

.fade-enter, .fade-leave-to
  opacity: 0
</style>
